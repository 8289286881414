import React from "react"
import { Helmet } from "react-helmet"

const CreateHead = props => {
  const headInfo = {
    title: props.title ? props.title : "Welcome to Medtech",
    description: props.description
      ? props.description
      : "Help develop our community for the benefit of prostate cancer sufferers and their families.",
    url: props.url ? props.url : process.env.GATSBY_API_URL,
    image: props.img
      ? props.img
      : process.env.GATSBY_API_URL + "social.png",
  }

  return (
    <Helmet>
      <html lang="en" />
      <title>{headInfo.title}</title>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, user-scalable=no"
      />

      <meta name="description" content={headInfo.description} />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color" content="#ffffff"></meta>
      <link rel="manifest" href="/manifest.json" />
      <meta property="og:url" content={headInfo.url} />
      <meta property="og:title" content={headInfo.title} />
      <meta property="og:description" content={headInfo.description} />
      <link rel="image_src" href={headInfo.image}></link>
      <meta property="og:image" content={headInfo.image} />

      <meta property="og:site_name" content={headInfo.title} />

      <meta name="twitter:title" content={headInfo.title} />
      <meta name="twitter:description" content={headInfo.description} />
      <meta name="twitter:image" content={headInfo.image} />
      <meta name="twitter:card" content="summary_large_image"></meta>
      <meta name="twitter:image:alt" content={headInfo.title}></meta>

      {props.children}
    </Helmet>
  )
}


export default CreateHead
