import React from 'react'
import styled from "@emotion/styled";
import { toRem, breakpoints } from "@utils/mixins";
import Grid from "@material-ui/core/Grid";

export const Container = styled.header`
  text-align: center;
  /* height: 170px; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${toRem(17)} 0;
  background-color: ${props => props.background || "#ffffff"};

  @media ${breakpoints.tablet} {
  }

  img {
    max-width: ${props => props.mobile || props.laptop}px;
    max-height: 100%;

    @media ${breakpoints.tablet} {
      max-width: ${props => props.laptop}px;
    }

    @media ${breakpoints.bigDesktop} {
      min-width: ${props => props.desktop}px;
    }

    @media ${breakpoints.largeDesktop} {
      min-width: ${props => props.big || props.desktop}px;
      width: calc(6vw + ${props => props.big || props.desktop}px);
      max-width: 10vw;
    }
  }
`

const Logo = ({ background, laptop, desktop, mobile, big}) => {
  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Container
          background={background}
          laptop={laptop}
          desktop={desktop}
          mobile={mobile}
          big={big}
        >
          <img src="/images/blue_ribbon3.png" alt="MedTech" />
        </Container>
      </Grid>
    </Grid>
  )
}

export default Logo
